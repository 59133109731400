<template>
    <div class="content">
        <div class="left">
            <div class="left_img">
                <img class="img" style="margin-left: 25px;margin-top: -30px;" src="../../assets/pcb.png">
                <img class="img" src="../../assets/pc.jpg">
            </div>
        </div>
        <div class="right">
            <div class="right_wrap" >
                <div class="title">PC助手·九阴全系列解决方案</div>
                <div class="desc">全方位的、便捷的30多项功能祝你江湖无忧!</div>
                <div class="desc" style="margin-top: -120px;font-size:23px">1.7.10更新公告:<br/>
                    1.新增-漠西-碧落,黄泉势力任务,进入/离开漠西任务<br/>
                    2.新增-生活-养蚕功能<br/>
                    3.优化挂机吃历练丹<br/>
                    4.修复漠西-红尘势力已知bug<br/>
                    5.优化拉镖近期反馈问题<br/>
                </div>

                <div class="btn_wrap">
                    <div class="btn" @click="pcClick()">
                        <span class="btn_icon"></span>PC助手下载
                    </div>
                    <div class="btn" @click="newClick()">
                        <span class="btn_icon"></span>新手教程
                    </div>
                </div>

                <div class="btn_desc">#win7系统支持后台多开、win10、win11系统前台使用(免费下载、仅限九阴PC助手会员使用)</div>
<!--                <div class="btn_desc">仅限九阴PC助手会员使用</div>-->
            </div>
        </div>
        <div class="foot" id="foot">Copyright ? 2023-2024ICP证:
<!--            <a href="https://beian.miit.gov.cn/" target="_blank">辽ICP备2022004881号-1 </a>-->
            <a href="https://beian.miit.gov.cn/" target="_blank">辽ICP备20002243号-1 </a>
            负责人邮箱: 34543686@qq.com</div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
            }
        },
        computed:{
        },
        watch: {
        },
        methods:{

            pcClick(e){
                this.$message.success("程序下载中...");
                location.href = this.$tools.url;
            },

            newClick(){
                localStorage.setItem("tokenName","t");
                localStorage.setItem("tokenValue","ghkshfjdhkjfhdskjhsjhfuiwf");
                this.$router.push({ name:'home' });
            }

        },

        mounted() {
            if(document.documentElement.clientWidth <= 1000){
                this.$confirm("检测当前分辨率较小,内容显示可能不完全", {
                    confirmButtonText: '确定',
                    showCancelButton: false,
                    type: 'warning'
                }).then(() => {
                    //location.reload();
                }).catch(e =>{
                    //location.reload();
                });
                return;
            }
            let u = navigator.userAgent;
            console.log('uuu', u)
            //如果是edge
            if(u.indexOf('Edge') > -1 || u.indexOf('Edg') > -1){
                this.$confirm("请勿使用Edge浏览器,需更换其他浏览器!", {
                    confirmButtonText: '确定',
                    showCancelButton: false,
                    type: 'warning'
                }).then(() => {
                    //location.reload();
                }).catch(e =>{
                    //location.reload();
                });
                return;
            }
        }
    };
</script>

<style scoped>
    .content{
        position: relative;
        width: 100vw;
        height: 100vh;
        min-height: 600px;
        min-width: 1300px;
        background: rgba(255, 253, 254, 0.97);
    }
    .left{
        position: absolute;
        left: 0;
        top: 0;
        width: 50%;
        height: 100%;
        transition: all 0.5s ease-in-out;
    }
    .left_img{
        position: absolute;
        left: 0;
        right: 0;
        top: 20vh;
        margin: auto;
        height: 520px;
        width: 595px;
        box-shadow: 0 2px 8px rgba(173, 173, 173, 0.5);
        border-radius: 10px;
    }
    .img{
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        border-radius: 10px;
    }

    .right{
        position: absolute;
        right: 0;
        top: 0;
        width: 50%;
        height: 100%;
        transition: all 0.5s ease-in-out;
    }
    .right_wrap{
        position: absolute;
        right: 0;
        top: 20vh;
        width: 100%;
        height: 50%;
        transition: all 0.5s ease-in-out;
    }

    .title{
        position: relative;
        height: 50px;
        width: 100%;
        font-weight: 550;
        font-size: 34px;
        line-height: 45px;
        color: rgba(0,0,0,.8);
        display: flex;
        align-items: center;
        justify-content: start;
    }
    .desc{
        position: relative;
        width: 100%;
        font-size: 16px;
        line-height: 142%;
        color: rgba(0,0,0,.6);
        margin-top: 12px;
        margin-bottom: 170px;
        display: flex;
        align-items: center;
        justify-content: start;
    }

    .btn_wrap{
        position: relative;
        display: flex;
        justify-content: start;
        align-items: center;
        margin-top: -130px;
    }
    .btn{
        position: relative;
        flex: none;
        border-radius: 8px;
        font-size: 16px;
        line-height: 20px;
        color: #fff;
        width: 190px;
        height: 52px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #f60;
        font-weight: 800;
        margin-right: 30px;
    }
    .btn:hover{
        cursor: pointer;
        opacity: 0.8;
    }
    .btn_icon{
        width: 20px;
        height: 20px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50%;
        background-image: url(//res.orayimg.com/sunlogin/1.0/img/cd57228.png);
        margin-right: 8px;
    }
    .btn_desc{
        position: relative;
        text-align: left;
        margin-top: 12px;
        font-size: 12px;
        line-height: 16px;
        color: rgba(0,0,0,.4);
        display: flex;
        align-items: center;
        justify-content: start;
    }

    .foot{
        position: absolute;
        height: 30px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        color: #777777;
        bottom: 0;
    }

</style>
